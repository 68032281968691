/* eslint-disable @typescript-eslint/no-misused-promises */
import { FontIcons, TileButton, TileButtons } from '@brandfolder/react';
import { t, Trans } from '@lingui/macro';
import { Field, Form, Formik } from 'formik';
import React, { FunctionComponent, ReactNode, useState } from 'react';

import { createAssets } from '@api/v4/assets/assets';
import {
  DesignHuddleProjectDimensionsPostClient,
  DesignHuddleProjectBackgroundImagePostClient,
  DesignHuddleProjectTemplateIdPostClient,
} from '@api/v4/private/resources/design_huddles';
import {
  designHuddleProjectGetNew,
  designHuddleProjectPost
} from '@api/v4/private/resources/design_huddles/projects';
import { BFLoader } from '@components/common/loader/main';
import { PrimaryButton } from '@components/library/button';
import { StandardTextField } from '@components/library/text-field';
import {
  CreateNewAssetTypes,
  CreateProjectType,
  CreateProjectTypes,
  SubmittingMessages
} from '@components/show_page/sections/asset/custom-templates';
import { ChooseCanvasSize } from '@components/show_page/sections/asset/custom-templates/ChooseCanvasSize';
import { CreateFromTemplate } from '@components/show_page/sections/asset/custom-templates/CreateFromTemplate';
import {
  getCanvasSizes,
  getInitialValues,
  getValidationSchema,
  getSubmittingMessages
} from '@components/show_page/sections/asset/custom-templates/helpers';
import { UploadAnImage } from '@components/show_page/sections/asset/custom-templates/UploadAnImage';
import { getFilestackCredentials, getIsFilestackUrl } from '@helpers/filestack';

import './styles/create-project.scss';

interface CreateProjectProps {
  assetType: CreateNewAssetTypes;
  sectionKey: string;
  exclude?: CreateProjectTypes[];
}

export const CreateProject: FunctionComponent<CreateProjectProps> = ({ assetType, sectionKey, exclude = [] }) => {
  const resourceKey = BFG.resource.key;
  const resourceType = BFG.resource.type;

  const createProjectTypes: CreateProjectType[] = [
    {
      className: 'create-project__canvas-size',
      heading: t`Choose canvas size`,
      icon: FontIcons.Canvas,
      id: CreateProjectTypes.ChooseCanvasSize
    },
    {
      className: 'create-project__upload-an-image',
      heading: t`Upload an image`,
      icon: FontIcons.Image,
      id: CreateProjectTypes.UploadAnImage
    },
    {
      className: 'create-project__create-from-existing-template',
      heading: t`Create from existing template`,
      icon: FontIcons.Template,
      id: CreateProjectTypes.CreateFromExistingTemplate
    }
  ].filter((createProjectType) => {
    return !exclude.includes(createProjectType.id);
  });

  const canvasSizes = getCanvasSizes(assetType);
  const initialValues = getInitialValues(assetType);
  const validationSchema = getValidationSchema(assetType);

  const [submittingMessage, setSubmittingMessage] = useState(null);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers): Promise<void> => {
        const { setSubmitting, validateForm } = formikHelpers;

        const errors = await validateForm(values);
        if (Object.keys(errors).length > 0) return;

        const {
          backgroundImageUrl,
          dimensions,
          mediaType,
          projectTitle,
          projectType,
          sizeToBackgroundImage,
          templateId
        } = values;

        let data: null
        | DesignHuddleProjectDimensionsPostClient
        | DesignHuddleProjectBackgroundImagePostClient
        | DesignHuddleProjectTemplateIdPostClient = null;

        if (projectType === CreateProjectTypes.ChooseCanvasSize) {
          data = {
            dimensions,
            mediaType,
            projectTitle
          } as DesignHuddleProjectDimensionsPostClient;
        }
        if (projectType === CreateProjectTypes.UploadAnImage) {
          const creds = getFilestackCredentials();
          data = {
            backgroundImageUrl,
            mediaType,
            policy: creds && getIsFilestackUrl(backgroundImageUrl) ? creds.policy : undefined,
            projectTitle,
            signature: creds && getIsFilestackUrl(backgroundImageUrl) ? creds.signature : undefined,
            sizeToBackgroundImage
          } as DesignHuddleProjectBackgroundImagePostClient;
        }
        if (projectType === CreateProjectTypes.CreateFromExistingTemplate) {
          data = {
            projectTitle,
            templateId
          } as DesignHuddleProjectTemplateIdPostClient;
        }

        try {
          setSubmittingMessage(getSubmittingMessages(SubmittingMessages.CreatingProject));
          const createProjectResponse = await designHuddleProjectPost({
            data,
            resourceKey,
            resourceType
          });
          const projectId = createProjectResponse.data.project_id;

          if (projectType === CreateProjectTypes.ChooseCanvasSize) {
            window.location.assign(
              `/design_editor/project/${projectId}/${resourceType}s/${resourceKey}/?section_key=${sectionKey}`
            );
          } else {
            const projectNewResponse = await designHuddleProjectGetNew({
              projectId,
              resourceKey,
              resourceType
            });

            setSubmittingMessage(getSubmittingMessages(SubmittingMessages.CreatingAsset));
            const createAssetsResponse = await createAssets({
              url: `/api/v4/${resourceType}s/${resourceKey}/assets?fast_jsonapi=true`,
              headers: { 'X-Brandfolder-Change-Agent': 'Custom Templating Integration' },
              section_key: sectionKey, // eslint-disable-line @typescript-eslint/naming-convention
              data: {
                attributes: [{
                  name: projectNewResponse.data.relationships.assets.data.attributes.name,
                  attachments: [
                    {
                      // eslint-disable-next-line @typescript-eslint/naming-convention
                      external_id: projectNewResponse.data.relationships.attachments.data.attributes.external_id,
                      filename: projectNewResponse.data.relationships.attachments.data.attributes.filename,
                      source: projectNewResponse.data.relationships.attachments.data.attributes.source,
                      url: projectNewResponse.data.relationships.attachments.data.attributes.url,
                    }
                  ],
                }]
              }
            });

            setSubmittingMessage(getSubmittingMessages(SubmittingMessages.Redirecting));
            const assetId = createAssetsResponse.data[0].id;
            window.location.assign(
              `/design_editor/project/${projectId}/${assetId}/?section_key=${sectionKey}`
            );
          }
        } catch (err) {
          Notify.create({
            title:t`There was an error saving your project. Please try again.`,
            type: 'error'
          });
          setSubmitting(false);
          setSubmittingMessage('');
        }
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={validationSchema}
    >
      {(formikBag): ReactNode => {
        const {
          errors,
          setFieldValue,
          setFieldTouched,
          submitCount,
          touched,
          validateField,
          values
        } = formikBag;

        const {
          backgroundImageUrl,
          dimensions,
          canvasSize,
          filename,
          projectType,
          templateId
        } = values;

        const hasSubmitted = submitCount > 0;
        const isCustom = canvasSizes[canvasSize].length === 0;

        const showSubmit = (): boolean => {
          if (projectType === null) {
            return false;
          }
          return true;
        };

        if (submittingMessage) {
          return (
            <div className="create-project__submitting">
              <BFLoader />
              <p>{submittingMessage}</p>
            </div>
          );
        }

        return (
          <Form className="create-project__form">
            <div className="create-project__project-title--padding">
              <Field name="projectTitle">
                {({ field }): ReactNode => (
                  <StandardTextField
                    {...field}
                    autoFocus
                    className="create-project__project-title"
                    error={touched.projectTitle && errors.projectTitle}
                    id="project-title"
                    label={t`Name your project`}
                    maxLength={50}
                    onBlur={(): void => {
                      if (touched.projectTitle) {
                        validateField('projectTitle');
                      }
                    }}
                    placeholder={t`Project name`}
                  />
                )}
              </Field>
            </div>
            <div className="create-project__padding create-project__scroll">
              <p className="create-project__p">
                <Trans>Start by choosing a canvas size, uploading an image, or create from an existing template.</Trans>
              </p>
              <TileButtons className="create-project__project-types">
                {createProjectTypes.map((createProjectType) => {
                  const { className, heading, icon, id } = createProjectType;
                  return (
                    <TileButton
                      key={id}
                      aria-pressed={projectType === id}
                      className={className}
                      heading={heading}
                      icon={icon}
                      onClick={(): void => {
                        // don't switch and reset
                        // unless going to a different project type
                        if (projectType !== id) {
                          setFieldValue('projectType', id);

                          // we don't want to reset project title when switching
                          // so we can't call resetForm() here
                          // so we need to reset things manually

                          // choose canvas size
                          setFieldValue('canvasSize', initialValues.canvasSize);
                          setFieldValue('dimensions', initialValues.dimensions);
                          setFieldTouched('dimensions', undefined);

                          // upload an image
                          setFieldValue('backgroundImageUrl', initialValues.backgroundImageUrl);
                          setFieldTouched('backgroundImageUrl', undefined);
                          setFieldValue('filename', initialValues.filename);
                          setFieldValue('sizeToBackgroundImage', initialValues.sizeToBackgroundImage);
                          setFieldTouched('sizeToBackgroundImage', undefined);

                          // create from template
                          setFieldValue('templateId', initialValues.templateId);
                          setFieldTouched('templateId', undefined);
                        }
                      }}
                    />
                  );
                })}
              </TileButtons>
              {projectType === CreateProjectTypes.ChooseCanvasSize && (
                <ChooseCanvasSize
                  assetType={assetType}
                  canvasSize={canvasSize}
                  canvasSizes={canvasSizes}
                  dimensions={dimensions}
                  errors={errors}
                  hasSubmitted={hasSubmitted}
                  initialValues={initialValues}
                  isCustom={isCustom}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  validateField={validateField}
                />
              )}
              {projectType === CreateProjectTypes.UploadAnImage && (
                <UploadAnImage
                  backgroundImageUrl={backgroundImageUrl}
                  errors={errors}
                  filename={filename}
                  hasSubmitted={hasSubmitted}
                  initialValues={initialValues}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  validateField={validateField}
                />
              )}
              {projectType === CreateProjectTypes.CreateFromExistingTemplate && (
                <CreateFromTemplate
                  errors={errors}
                  hasSubmitted={hasSubmitted}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  templateId={templateId}
                  validateField={validateField}
                />
              )}
            </div>
            {showSubmit() && (
              <div className="create-project__footer">
                <PrimaryButton
                  id="create-project-submit"
                  type="submit"
                >
                  <Trans>Create Project</Trans>
                </PrimaryButton>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
