import { StandardAlert } from '@brandfolder/react';
import { sanitize } from '@brandfolder/utilities';
import { t, Trans } from '@lingui/macro';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
  add as addFilter,
  remove as removeFilter,
} from '@api/search-filters';
import getSearchableThings from '@api/searchable-things';
import getResourceAssets from '@api/v4/resources/assets';
import { getCustomFieldKeys } from '@api/v4/resources/custom_fields';
import {
  formatLabelsFlat,
  formatLabelsTree,
  get as getLabels,
} from '@api/v4/resources/labels';
import AdvancedDownloadModal from '@components/advanced_download/main';
import { determineUGTLocale } from '@components/asset/modal/tabs/edit/helpers';
import { flattenCustomFieldKeysList } from '@components/asset/modal/tabs/edit/helpers';
import FetchControllersWrapper from '@components/common/fetch_controllers_wrapper';
import { ProcessingLoader } from '@components/common/loader/main';
import { DownloadAlertDialog } from '@components/download_alert/DownloadAlertDialog';
import ApproveRequestActions from '@components/request_download/approve_request_actions';
import { CustomFieldsEvents } from '@components/settings/brandfolder/custom-fields-tab/CustomFieldsFormEnums';
import InternalShareBar from '@components/show_page/other/internal_share_bar';
import { canvaApiKey } from '@components/show_page/sections/asset/canva/helper';
import { fetchShareManifest, shareManifestExpired } from '@components/show_page/sections/helpers';
import SearchDetails from '@components/show_page/sections/search_details/SearchDetails';
import filterLogic from '@components/show_page/sections/section_search/filterLogic';
import { FilterType, SearchOperatorOptions } from '@components/show_page/sections/section_search/SearchTypes';
import {
  getDefaultViewOptions,
  getSortOption,
  setUserViewOptions,
  updateUserViewOptionsInLocalStorage,
} from '@components/show_page/sections/view_options/helper';
import ViewOptions from '@components/show_page/sections/view_options/ViewOptions';
import Web2PrintModal from '@components/web_2_print/web_2_print_modal';
import { manageFilters, defaultFilters } from '@helpers/filters';
import { defaultSearchFilterOperators, Brandfolder, Collection } from '@helpers/show_page_helpers';
import { getStorage, setStorage, StorageTypes } from '@helpers/storage';

import BulkSelectBar from "../bulk_actions/BulkSelectBar";

import CustomDragLayer from './asset/custom_drag_layer';
import FiltersDrawer from "./filters_drawer/main";
import LabelsBreadcrumbs from "./labels_breadcrumbs/main";
import PinnedSearches from "./pinned_searches/main";
import Section from './section';
import SectionSearch from "./section_search/main";
import SectionSort from "./section_sort/SectionSort";
import SectionsContext from './sectionsContext';

import './styles/_search_and_view_details.scss';
import './styles/show_page.scss';

class ShowPage extends React.Component {
  constructor(props) {
    super(props);

    const {
      defaultSortOrder,
      paginatePer,
    } = this.props;

    this.state = {
      activeLabelKey: null,
      activeSection: 'all',
      assets: {},
      assetsProcessing: 0,
      canvaApi: null,
      customFieldKeys: null,
      dependentCustomFields: null,
      downloadAlertCallback: undefined,
      entireSectionSelected: new Set(),
      filters: { ...defaultFilters() },
      filtersDrawerOpen: 'none', // 'none', 'labels', 'advanced-filters'
      filterStrings: [],
      internalShareLinkExpired: false,
      labels: {},
      lastSelected: null,
      lastVisited: {},
      loading: true,
      searchableThings: null,
      searchFilters: this.props.searchFilters,
      searchKey: 'none',
      searchFilterOperators: this.initialSearchFilterOperators(),
      searchQuery: this.initialSearchQuery(),
      searchSubmitted: !!this.initialSearchQuery(),
      sections: {},
      selectedAssetKeys: new Set(),
      selectedViewOnlyAssetKeys: new Set(),
      showAdvancedDownloadModal: false,
      showDownloadAlert: false,
      showWebToPrintModal: false,
      tasks: {},
      userViewOptions: setUserViewOptions(defaultSortOrder, paginatePer, this.initialSearchOperator()),
      windowDimensions: {}, // updates via 'resize' event listener
    };

    // So we can abort requests later if necessary;
    // it's not a piece of state because it won't have
    // any effect on re-rendering the component.
    this.activeRequests = {};
  }

  fetchInitialData() {
    this.fetchSections().then(() => {
      this.fetchLabels();
      this.fetchSearchableThings();
      this.updateEventListeners('add');
      this.fetchRequiredCustomFields();
      this.initializeCanva();
    });

    this.copyLabelsDrawerOpenSetting();
    this.addCustomFieldsSettingsListener();
  }

  componentDidMount() {
    if (BFG.manifestDigest && BFG.manifest?.key) {
      fetchShareManifest(BFG.manifest.key).then((shareManifest) => {
        const expired = shareManifestExpired(shareManifest);
        this.setState({ internalShareLinkExpired: expired })
        if (!expired) {
          this.fetchInitialData();
        }
      });
    } else {
      this.fetchInitialData();
    }
  }

  componentWillUnmount() {
    this.updateEventListeners('remove');
    this.removeCustomFieldsSettingsListener();
  }

  initializeCanva = () => {
    const canvaEnabled = BFG.hasFeature('canva_button');
    if (canvaEnabled) {
      /**
       * https://docs.developer.canva.com/button/javascript/api-reference/initialize
       */
      const script = document.createElement('script');
      script.src = 'https://sdk.canva.com/designbutton/v2/api.js';
      script.onload = () => {
        (async () => {
          if (!window.Canva || !window.Canva.DesignButton) {
            return;
          }

          /**
           * IMPORTANT: api should only be initialized once, and must live here
           * at the top level containing component, otherwise multiple
           * Canva div's and iframe's load, making the integration unstable and unusable
           */

          const api = await window.Canva.DesignButton.initialize({
            apiKey: canvaApiKey
          });

          this.setState({
            canvaApi: api
          });
        })();
      };
      document.body.appendChild(script);
    }
  }

  getSection(sectionKey) {
    const { sections, assets } = this.state;
    const result = sections[sectionKey];
    if (result) {
      return result;
    }

    return {
      currentPage: 1,
      total: assets[sectionKey] ? assets[sectionKey].length : 0,
      totalPages: 1,
      loading: false,
    };
  }

  get resourceKey() {
    return Collection.key ? Collection.key : Brandfolder.key;
  }

  get resourceType() {
    return Collection.key ? "Collection" : "Brandfolder";
  }

  updateUserViewOptions = (option, updatedValue) => {
    let triggerFetchSections = false; // instantiate here so it's available in callback below

    this.setState((prevState) => {
      const { defaultSortOrder, paginatePer } = this.props;
      const { searchQuery, searchSubmitted } = prevState;
      const updatedState = { ...prevState.userViewOptions };
      const { assetsPerPage, searchOperator, selectedUGTLocale, sortOption } = updatedState;
      const previousValue = updatedState[option];
      const defaultValues = getDefaultViewOptions(defaultSortOrder, paginatePer);

      // reset to default values
      if (option === 'reset') {
        triggerFetchSections = sortOption !== defaultValues.sortOption
          || assetsPerPage !== defaultValues.assetsPerPage
          || selectedUGTLocale !== defaultValues.selectedUGTLocale
          || searchOperator !== defaultValues.searchOperator;
        return { userViewOptions: defaultValues };
      }

      // flip state for a toggle
      if (option === 'showCustomFields' || option === 'showEmptySections' || option === 'showSections' || option === 'showTaskDetails') {
        updatedState[option] = !previousValue;
        triggerFetchSections = option === 'showSections';

        return { userViewOptions: updatedState };
      }

      // if no change, avoid updating state and avoid rerender
      if (previousValue === updatedValue) {
        return null;
      }

      // update for a value that's a string
      if (option === 'selectedUGTLocale' || option === 'searchOperator') {
        triggerFetchSections = option === 'selectedUGTLocale' || (searchQuery && searchSubmitted);
        updatedState[option] = updatedValue;
        if (option === 'searchOperator') {
          this.clearSearchParams();
        }
        return { userViewOptions: updatedState };
      }

      if (option === 'sortOption' || option === 'assetsPerPage') {
        triggerFetchSections = (previousValue !== updatedValue);
      }

      updatedState[option] = updatedValue;
      return { userViewOptions: updatedState };
    }, () => {
      updateUserViewOptionsInLocalStorage(this.state.userViewOptions);
      if (triggerFetchSections) this.fetchSections();
    });
  }

  toggleFiltersDrawer = (source) => {
    this.setState((prevState) => {
      const { filtersDrawerOpen } = prevState;
      let updatedFiltersDrawerOpen = 'none';

      if (filtersDrawerOpen === 'none') { // was CLOSED
        if (source === 'labels-drawer') {
          updatedFiltersDrawerOpen = 'labels';
        } else {
          updatedFiltersDrawerOpen = 'advanced-filters';
        }
      }

      if (BFG.context.hasFeature("labels")) { // was OPEN
        if (source === 'labels-drawer' && filtersDrawerOpen === 'advanced-filters') {
          updatedFiltersDrawerOpen = 'labels';
        } else if (source === 'advanced-filters' && filtersDrawerOpen === 'labels') {
          updatedFiltersDrawerOpen = 'advanced-filters';
        } else if (source === 'section-search' && filtersDrawerOpen === 'labels') {
          updatedFiltersDrawerOpen = 'advanced-filters';
        }
      }

      setStorage(StorageTypes.Local, 'drawerOpen', updatedFiltersDrawerOpen);

      return { filtersDrawerOpen: updatedFiltersDrawerOpen };
    });
  }

  updateEventListeners = (action) => {
    const triggerActivateLabel = ({ detail }) => {
      const labelkey = detail;
      this.activateLabel(labelkey);
    };

    const toggleFilters = (e) => {
      if (e.key === 'b' && (e.getModifierState("Meta") || e.getModifierState("Control"))) {
        this.toggleFiltersDrawer('advanced-filters');
      }
    };

    const launchModal = (e, modalType, dismissDialog = true) => {
      const { assetKey, attachmentKey, downloadCallback } = e.detail;
      if (dismissDialog) {
        BF.dialog.dismiss();
      }
      this.setState({
        lastVisited: { assetKey, attachmentKey },
        [modalType]: true,
        downloadAlertCallback: downloadCallback,
      });
    };

    const updateResize = () => {
      this.setState({
        windowDimensions: {
          innerHeight: window.innerHeight,
          innerWidth: window.innerWidth,
        }
      });
    };

    // disable text highlighting when shift selecting assets
    // shoutout to https://stackoverflow.com/questions/1527751/disable-text-selection-while-pressing-shift
    const updateShift = (e) => {
      document.onselectstart = function () {
        return !(e.key == "Shift" && e.shiftKey);
      }
    }

    if (action === 'add') {
      if (!BFG.showPageLite) {
        window.addEventListener('activateLabel', triggerActivateLabel);
        window.addEventListener('keydown', toggleFilters);
      }
      window.addEventListener('resize', updateResize);
      window.addEventListener('launchAdvancedDownload', (e) => launchModal(e, 'showAdvancedDownloadModal'));
      window.addEventListener('launchWebToPrint', (e) => launchModal(e, 'showWebToPrintModal'));
      window.addEventListener('launchDownloadAlert', (e) => launchModal(e, 'showDownloadAlert', false));
      window.addEventListener('keyup', updateShift);
      window.addEventListener('keydown', updateShift);
    }

    if (action === 'remove') {
      if (!BFG.showPageLite) {
        window.removeEventListener('activateLabel', triggerActivateLabel);
        window.removeEventListener('keydown', toggleFilters);
      }
      window.removeEventListener('resize', updateResize);
      window.removeEventListener('launchAdvancedDownload', (e) => launchModal(e, 'showAdvancedDownloadModal'));
      window.removeEventListener('launchWebToPrint', (e) => launchModal(e, 'showWebToPrintModal'));
      window.removeEventListener('launchDownloadAlert', (e) => launchModal(e, 'showDownloadAlert', false));
      window.removeEventListener('keyup', updateShift);
      window.removeEventListener('keydown', updateShift);
    }

    return undefined;
  }

  clearSelected = () => {
    this.setState({
      entireSectionSelected: new Set(),
      lastSelected: null,
      selectedAssetKeys: new Set(),
      selectedViewOnlyAssetKeys: new Set()
    });
  }

  toggleSelected = (options = {}) => {
    const { assetKeys, sectionKey, selectAllPages, selectCurrentPage, viewOnlyAssetKeys } = options;
    const { downloadRequestId } = this.props;

    this.setState(({ entireSectionSelected, selectedAssetKeys, selectedViewOnlyAssetKeys }) => {
      /** new sets are required so we don't mutate state */
      const fullySelectedSections = new Set(entireSectionSelected);
      const selectedKeys = new Set(selectedAssetKeys);
      const selectedViewOnlyKeys = new Set(selectedViewOnlyAssetKeys);

      const keys = Array.isArray(assetKeys) ? assetKeys : [assetKeys];
      const viewOnlyKeys =
        !downloadRequestId && Array.isArray(viewOnlyAssetKeys)
          ? viewOnlyAssetKeys
          : !downloadRequestId && viewOnlyAssetKeys
            ? [viewOnlyAssetKeys]
            : [];

      let lastSelected;

      keys.forEach((assetKey) => {
        if (!selectCurrentPage && !selectAllPages && selectedKeys.has(assetKey)) {
          selectedKeys.delete(assetKey);
        } else {
          selectedKeys.add(assetKey);
        }

        lastSelected = assetKey;
      });

      viewOnlyKeys.forEach((viewOnlyAssetKey) => {
        if (!selectCurrentPage && !selectAllPages && selectedViewOnlyKeys.has(viewOnlyAssetKey)) {
          selectedViewOnlyKeys.delete(viewOnlyAssetKey);
        } else {
          selectedViewOnlyKeys.add(viewOnlyAssetKey);
        }
      });

      if (selectAllPages) {
        fullySelectedSections.add(sectionKey);
      } else if (sectionKey) {
        fullySelectedSections.delete(sectionKey);
      }

      return {
        entireSectionSelected: fullySelectedSections,
        lastSelected,
        selectedAssetKeys: selectedKeys,
        selectedViewOnlyAssetKeys: selectedViewOnlyKeys
      };
    });
  }

  toggleActiveSection = (sectionKey) => {
    const { activeSection, userViewOptions } = this.state;

    const updatedActiveSection = activeSection === sectionKey ? 'all' : sectionKey;
    this.setState({ activeSection: updatedActiveSection }, () => {
      if (!userViewOptions?.showSections) this.fetchSections();
    });
  }

  addRemovePinnedSearch = async () => {
    const { searchFilters, searchQuery } = this.state;
    if (searchFilters.map((filter) => filter.query).includes(searchQuery)) {
      const pinnedSearch = [...searchFilters].filter((item) => item.query === searchQuery);
      const updatedFilters = [...searchFilters].filter((filter) => (filter.key !== pinnedSearch[0].key));

      removeFilter(pinnedSearch[0].key);
      this.setState({ searchFilters: updatedFilters });
    } else {
      this.showFilterModal();
    }
  }

  submitSearchQuery = (filter, keypress, options = {}) => {
    let searchKey = options.searchKey || 'none';
    const { searchFilters } = this.props;

    this.setState({ loading: true }, () => {
      this.setState((state) => {
        const isPinnedSearch = searchFilters.filter((item) => item.query === filter);
        const searchQuery = filter === state.searchQuery && isPinnedSearch.length > 0 && !keypress ? null : filter;

        if (searchQuery) {
          Insight.trackSearchEvent(searchQuery);
        }

        if (!searchQuery) {
          // This may seem weird, but it's actually pretty great;
          // We use searchKey as the input to the `key` prop on the
          // section search. When it changes, the section search re-mounts
          // and updates its own internal state based on its props.
          // In cases where we clear the search, it's best to set it to
          // something unique to force this refresh. Random works pretty ok.
          searchKey = `none-${Math.random()}`;
        }

        if (filter === null || !searchQuery) {
          this.clearSearchParams();
        }

        return {
          ...options?.resetSearchOperator && {
            userViewOptions: {
              ...state.userViewOptions,
              searchOperator: BFG?.brandfolderSettings?.default_search_operator || SearchOperatorOptions.OR,
            }
          },
          searchQuery,
          searchSubmitted: !!filter,
          recievedSections: 0,
          searchKey,
          sections: {},
        };
      }, () => {
        this.fetchSections();
      });
    });
  }

  clearSearchParams = () => {
    // when clicking on clear search, changing the search operator, or deleting the search input
    // (only after coming to the page from a search share link)
    // we want to remove any query strings from our url
    // because otherwise refreshing brings back the search
    // which may be unexpected for the user
    if (window.location.search) {
      window.history.replaceState({}, document.title, `${window.location.protocol}//${window.location.host}${window.location.pathname}`);
    }
  }

  resetFilters = () => {
    this.setState({
      activeSection: 'all',
      filters: { ...defaultFilters() },
      filterStrings: [],
      searchFilterOperators: defaultSearchFilterOperators,
    }, this.fetchSections);
  }

  updateSearchFilterOperator = (filterType, operatorUpdate) => {
    // affects tags and custom fields as of 3/2021
    this.setState((prevState) => ({
      searchFilterOperators: {
        ...prevState.searchFilterOperators,
        ...filterType === FilterType.Tags && { tags: operatorUpdate },
        ...filterType === FilterType.CustomFields && {
          custom_fields: {
            ...prevState.searchFilterOperators.custom_fields,
            ...operatorUpdate,
          }
        },
      }
    }), () => {
      const { filters } = this.state;
      if (filters.tags?.length || Object.keys(filters?.custom_fields).length) {
        this.fetchSections();
      }
    });
  }

  updateFilters = (filterType, keyValue, subValue) => {
    const { filters, filterStrings } = this.state;
    if (filterType === 'reset') {
      this.resetFilters();
      return;
    }

    const results = manageFilters({
      filters,
      filterStrings,
      filterType,
      keyValue,
      subValue,
    });

    this.setState({
      ...results,
      loading: true,
    }, this.fetchSections);
  }

  activateLabel = (labelId) => {
    this.setState({ activeLabelKey: labelId }, () => {
      this.fetchSections();
    });
  }

  updateSearchQuery = (e, searchQuery) => {
    const search = searchQuery || e.target.value;

    this.setState({
      searchKey: search,
      searchQuery: search,
      searchSubmitted: !!searchQuery,
    }, () => {
      this.fetchSections();
    });
  }

  addRemoveSelected = (assetKeys, viewOnlyAssetKeys) => {
    this.setState(({ entireSectionSelected }) => ({
      selectedAssetKeys: new Set(assetKeys),
      entireSectionSelected: assetKeys.length ? entireSectionSelected : new Set(),
      selectedViewOnlyAssetKeys: new Set(viewOnlyAssetKeys || [])
    }));
  }

  selectAll = () => {
    this.setState({
      selectedAssetKeys: new Set(this.allAssetKeys()),
      selectedViewOnlyAssetKeys: new Set(this.allViewOnlyAssetKeys())
    });
  }

  /** Clicking "Select: All Visible" from the BulkSelectBar.jsx */
  selectAllVisible = () => {
    this.setState((prevState) => ({
      selectedAssetKeys: new Set([...prevState.selectedAssetKeys, ...this.allVisibleAssetKeys()]),
      selectedViewOnlyAssetKeys: new Set([...prevState.selectedViewOnlyAssetKeys, ...this.allVisibleViewOnlyAssetKeys()])
    }));
  }

  shiftSelect = (assetKey, _, viewOnlyAssetKeys) => {
    // TODO: just redo all of this :(
    const { lastSelected, selectedAssetKeys } = this.state;
    if (!lastSelected) {
      this.toggleSelected({
        assetKeys: assetKey,
        viewOnlyAssetKeys
      });
      return undefined;
    }

    const allAssetKeys = this.allAssetKeys();
    const lastSelectedPosition = allAssetKeys.indexOf(lastSelected);
    const position = allAssetKeys.indexOf(assetKey);
    const selectedSet = new Set(selectedAssetKeys); // TODO: how do I get this, but ORDERED?

    if (position !== lastSelectedPosition) {
      allAssetKeys.forEach((asset, i) => {
        const assetPosition = i;

        if ((assetPosition > lastSelectedPosition && assetPosition <= position)
          || (assetPosition < lastSelectedPosition && assetPosition >= position)
        ) {
          if (selectedSet.has(asset)) {
            selectedSet.delete(asset);
          } else {
            selectedSet.add(asset);
          }
        }
      });
      this.addRemoveSelected(Array.from(selectedSet));
    } else {
      this.toggleSelected({
        assetKeys: assetKey,
        viewOnlyAssetKeys
      });
    }
    this.setState({ lastSelected: assetKey });
    return undefined;
  }

  onNewAssets = (sectionKey, { data: newAssets }) => {
    this.setState((state) => {
      const { sections, assets, userViewOptions } = state;
      const { paginatePer } = this.props;
      const { sortOption } = userViewOptions;
      let { assetsPerPage } = userViewOptions;
      const assetsPerPageParam = this.urlParams().per && parseInt(this.urlParams().per, 10);

      // priority is url param, then user defined option, then plan admin defined value
      assetsPerPage = assetsPerPageParam || assetsPerPage || paginatePer;

      const sectionData = this.getSection(sectionKey);
      let { totalPages } = sectionData;
      const { total } = sectionData;

      let updatedAssets = [...assets[sectionKey]];

      const { selectedAssetKeys } = state;
      const newAssetsWithNewFlag = newAssets.map((newAsset) => {
        selectedAssetKeys.add(newAsset.id);
        return { ...newAsset, isNewAsset: true };
      });

      BF.fx.updateAssetPageCount(newAssetsWithNewFlag.length, true);

      const newTotal = total + newAssetsWithNewFlag.length;
      if (newTotal > assetsPerPage) {
        totalPages = Math.trunc(newTotal / assetsPerPage) + 1;
      }

      // user selected sort option determines how new assets are added to section
      // if needsSorting === false, new assets are simply added to the end of existing assets
      const newAssetsNeedSorting = 'created_at DESC,updated_at DESC,name ASC,name DESC'.includes(sortOption);
      const spaceOnPageForNewAssets = totalPages <= 1 && total < assetsPerPage;
      let updatedAssetsState;

      // update assets displayed in section on show page
      if (newAssetsNeedSorting || spaceOnPageForNewAssets) {
        if (newAssetsNeedSorting) {
          // add all new assets to beginning of section
          // for 'created_at DESC' and 'updated_at DESC', assets are correctly sorted with this addition
          // for 'name ASC' and 'name DESC', assets still need sorting
          updatedAssets = [...newAssetsWithNewFlag, ...updatedAssets];
        }

        if (sortOption === 'name ASC' || sortOption === 'name DESC') {
          const sortFactor = sortOption === 'name DESC' ? -1 : 1;
          const sortingFunction = (a, b) => {
            const nameA = a?.attributes?.name?.toUpperCase();
            const nameB = b?.attributes?.name?.toUpperCase();
            let comparison = 0;
            if (nameA > nameB) comparison = 1;
            if (nameB > nameA) comparison = -1;
            return comparison * sortFactor;
          };
          updatedAssets.sort(sortingFunction);
        }

        if (!newAssetsNeedSorting) {
          updatedAssets = [...updatedAssets, ...newAssetsWithNewFlag];
        }

        // remove extra assets if asset quantity exceeds the allowed assetsPerPage
        if (updatedAssets.length > assetsPerPage) {
          updatedAssets = updatedAssets.filter((asset, i) => (i < assetsPerPage));
        }

        updatedAssetsState = {
          ...assets,
          [sectionKey]: updatedAssets,
        };
      }

      return {
        ...updatedAssetsState && { assets: updatedAssetsState },
        sections: {
          ...sections,
          [sectionKey]: {
            ...sectionData,
            total: newTotal,
            totalPages,
          }
        },
        selectedAssetKeys,
      };
    });
  }

  copyLabelsDrawerOpenSetting = () => {
    /* copy old labels drawer setting into new drawerOpen setting */
    const getConvertedLabelsDrawerOpen = () => {
      const oldStoredLabelsDrawerOpen = getStorage(StorageTypes.Local, 'labelsDrawerOpen'); // old labels-specific value
      switch (oldStoredLabelsDrawerOpen) {
        case true:
          return 'labels';
        case false:
          return 'none';
        default:
          return null;
      }
    };

    if (!getStorage(StorageTypes.Local, 'drawerOpen')) {
      const oldLabelsDrawerOpen = getConvertedLabelsDrawerOpen();
      if (oldLabelsDrawerOpen !== null) {
        setStorage(StorageTypes.Local, 'drawerOpen', oldLabelsDrawerOpen);
      }
    }
  }

  fetchRequiredCustomFields = async () => {
    if (this.props.controlledCustomFieldsEnabled) {
      // impossible to have required custom fields if controlled custom fields not enabled
      const response = await getCustomFieldKeys({
        params: {
          include: 'dependent_custom_fields',
          order: 'asc',
          per: 3000,
          ugt_locale: determineUGTLocale(),
          fields: 'multi_value_enabled',
          sort_by: 'name',
        },
        resourceKey: BFG.resource.key,
        resourceType: BFG.resource.type
      });

      const customFieldKeys = flattenCustomFieldKeysList(response);
      const dependentCustomFields = response.included?.map((dependentCustomField) => dependentCustomField.attributes) || [];
      this.setState({ customFieldKeys, dependentCustomFields });
    }
  };

  handleCustomFieldsSettingsUpdated = (e) => {
    if (e?.detail?.refresh) {
      this.fetchRequiredCustomFields();
    }
  };

  addCustomFieldsSettingsListener = () => {
    window.addEventListener(CustomFieldsEvents.SettingsUpdated, this.handleCustomFieldsSettingsUpdated);
  }

  removeCustomFieldsSettingsListener = () => {
    window.removeEventListener(CustomFieldsEvents.SettingsUpdated, this.handleCustomFieldsSettingsUpdated);
  }

  fetchLabels = () => {
    if (!BFG.context.hasFeature("labels") || BFG.showPageLite) { return undefined; }
    let labelsTree; // initialize here to make available in callback
    getLabels({ fetchAll: true }).then((response) => {
      labelsTree = formatLabelsTree(response);
      const labelsFlat = formatLabelsFlat(response);
      this.setState({
        labels: {
          labelsFlat,
          labelsResponse: response,
          labelsTree,
        }
      }, () => {
        const hasLabels = labelsTree.children && labelsTree.children.length > 0;
        const drawerOpenLocal = getStorage(StorageTypes.Local, 'drawerOpen');

        // if user has not toggled the 'labels' or 'advanced-filters' drawer, start with the labels drawer open
        if (hasLabels && drawerOpenLocal === null) {
          this.setState({ filtersDrawerOpen: 'labels' });
        }
      });
    });

    return undefined;
  }

  fetchSearchableThings = () => {
    if (BFG.context.hasFeature("disable_advanced_filters") || BFG.showPageLite) { return undefined; }

    getSearchableThings({
      resourceType: window.SOURCE.resource_type,
      resourceKey: window.SOURCE.resource_key,
      ugtLocale: this.state.userViewOptions.selectedUGTLocale
    }).then((searchableThings) => { this.setState({ searchableThings }); });
    return undefined;
  }

  fetchSections = async () => {
    this.setState({ loading: true });
    let { sections, sectionsToShow } = this.props;
    const { activeSection, searchQuery, userViewOptions } = this.state;
    const newAssets = {};
    const newSections = {};
    const newTasks = {};

    if (!userViewOptions?.showSections) {
      if (activeSection !== 'all') {
        sections = sections.filter((section) => section.section_key === activeSection);
      } else {
        // for sectionless, create 1 section with the minimum attributes needed to render it
        sections = [{
          id: searchQuery ? 'search-results-assets-id' : 'show-page-assets-id',
          section_key: searchQuery ? 'search-results-assets' : 'show-page-assets',
        }];
        sectionsToShow = [sections[0].id];
      }
    }

    const promises = sections.map((section) => {
      const { section_key: sectionKey, id } = section;
      if (!sectionsToShow.includes(id)) { return undefined; }

      return this.updateSection(sectionKey, {
      }).then(({ assets, included, ...sectionData }) => {
        newAssets[sectionKey] = assets;
        newSections[sectionKey] = sectionData;
        // if you update tasks here, you'll also want to update tasks inside fetchSection below
        const tasks = included ? included.filter(({ type }) => type === "tasks") : [];
        if (tasks.length > 0) {
          newTasks[sectionKey] = tasks;
        }
      });
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      if (e.name === 'AbortError') {
        // this is fine, just means the user navigated away from the page before the request finished
        this.setState({ loading: false });
        return;
      } else if (e.status === 400 && searchQuery) {
        // A 400 status is returned from elastic search when the query is invalid
        Notify.create({
          title: t`Your search query is invalid.`,
          type: "error"
        });
      } else {
        Notify.create({
          title: t`Something went wrong. Please try again.`,
          type: "error"
        });
      }
    }

    this.setState({
      assets: newAssets,
      sections: newSections,
      loading: false,
      tasks: newTasks,
    });
  }

  fetchSection = async (sectionKey, options = {}) => {
    const { currentPage } = this.state.sections[sectionKey] || {};

    const { page: page_ } = options;
    const page = page_ || currentPage;

    this.setState((state) => ({
      sections: {
        ...state.sections,
        [sectionKey]: {
          ...state.sections[sectionKey],
          loading: true,
        },
      },
    }));

    const { assets, included, ...sectionData } = await this.updateSection(sectionKey, {
      page,
    });

    // if you update tasks here, you'll also want to update tasks inside fetchSections above
    const tasks = included ? included.filter(({ type }) => type === "tasks") : [];

    this.setState((state) => ({
      assets: {
        ...state.assets,
        [sectionKey]: assets,
      },
      sections: {
        ...state.sections,
        [sectionKey]: {
          ...sectionData,
          loading: false,
        },
      },
      tasks: {
        ...state.tasks,
        [sectionKey]: tasks
      }
    }));
  }

  updateSection = (sectionKey, options = {}) => {
    const {
      defaultSortOrder
    } = this.props;

    const {
      activeLabelKey,
      activeSection,
      filters,
      searchSubmitted,
      searchFilterOperators,
      searchQuery,
      userViewOptions
    } = this.state;

    const {
      assetsPerPage,
      searchOperator,
      selectedUGTLocale,
      showSections,
      sortOption
    } = userViewOptions;

    const { page: page_ } = options;
    const per = this.urlParams().per || assetsPerPage || this.props.paginatePer;
    const { currentPage } = this.state.sections[sectionKey] || {};
    const page = page_ || currentPage;

    const option = getSortOption(searchSubmitted, sortOption, defaultSortOrder);
    const [sort_by, order] = option.split(' ');

    const search = filterLogic(filters, this.escapedSearchQuery(searchQuery, searchOperator), searchFilterOperators);

    const params = {
      ...options,
      ...BFG.manifestDigest && { digest: BFG.manifestDigest },
      ...BFG.downloadRequestKey && { download_request_key: BFG.downloadRequestKey },
      ...!BFG.resource.is_workspace && { fast_jsonapi: true },
      fields: 'active_cdn_tracers,asset_data,assigned_users,attachment_count,availability,background_color,extension,html,position,printui_editor_link,prioritized_custom_fields,storyteq_editor_link,design_huddle_editor_link,tag_names,task_status,type,view_only,content_automation_editor_link',
      include: 'task',
      localUTC: Date.now(),
      ...activeLabelKey && { labelKeys: [activeLabelKey] },
      order,
      page,
      per,
      ...search && { search },
      ...search && {
        search_operator: (searchOperator === SearchOperatorOptions.Strict || searchOperator === SearchOperatorOptions.Quoted)
          ? SearchOperatorOptions.OR
          : searchOperator
      },
      sort_by,
      strict_search: searchOperator === SearchOperatorOptions.Strict,
      ...selectedUGTLocale && { ugt_locale: selectedUGTLocale },
    };

    const activeRequest = this.activeRequests[sectionKey];
    if (activeRequest) {
      if (!activeRequest.signal.aborted) {
        activeRequest.abort();
      }
    }
    const fetchController = (controller) => { this.activeRequests[sectionKey] = controller; };

    const fetchOptions = {
      activeSection,
      fetchController,
      isCollection: BFG.resource.type === 'collection',
      resourceType: BFG.resource.type,
      resourceKey: BFG.resource.key,
      ...options,
      sectionKey,
      showSections,
    };

    return getResourceAssets(params, fetchOptions);
  }

  updateSearchFilters = (updatedSearchFilters) => {
    this.setState({ searchFilters: updatedSearchFilters });
  }

  initialSearchOperator = () => {
    const searchOperator = this.urlParams().o; // string or undefined
    if (searchOperator) {
      return SearchOperatorOptions[searchOperator]; // string or undefined
    }
    return undefined;
  }

  initialSearchFilterOperators = () => {
    const searchOperator = this.initialSearchOperator();
    if (searchOperator) {
      return {
        ...defaultSearchFilterOperators,
        tags: searchOperator
      };
    }
    return defaultSearchFilterOperators;
  }

  initialSearchQuery = () => {
    let searchQuery = this.urlParams().q;
    if (searchQuery?.substr(0, 9) !== "section:") {
      try {
        searchQuery = decodeURIComponent(searchQuery?.replace(/[+]/g, ' ') || '');
      } catch (error) {
        console.log(error);
      }
      return searchQuery.replace(': "', ':"');
    }
    return undefined;
  }

  urlParams() {
    /**
     * {
     *  o: "foo"
     *  q: "bar"
     * }
     */
    const params = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => params[key] = value); // eslint-disable-line no-return-assign
    return params;
  }

  showFilterModal() {
    const query = encodeURIComponent(this.state.searchQuery);
    const title = t`Create New Pinned Search`;
    const base_url = "/api/search_filters/new";
    const token_param = `?token=${BF_Token}`;
    const query_param = `&query=${query}`;
    const resource_params = `&resource_key=${this.resourceKey}&resource_type=${this.resourceType}`;
    const url = base_url + token_param + query_param + resource_params;

    BF.dialog.render(title, url, () => {
      // TODO: implement this modal with react
      $('#dialog').find('.modal-dialog').addClass('modal-sweet-alert create-pin-modal');
      $('.j-search-filter-submit').on('click', () => {
        addFilter({
          resourceKey: this.resourceKey,
          resourceType: this.resourceType,
          label: sanitize($('#search_filter_label').val()),
          query: sanitize($('#search_filter_query').val()),
        }).then((response) => {
          BF.dialog.dismiss();
          this.setState({ searchFilters: response });
        }).catch((response) => {
          if (response.responseJSON.label) {
            $('#search_filter_label').addClass('invalid');
          }

          if (response.responseJSON.query) {
            $('#search_filter_query').addClass('invalid');
          }
        });
      });
      $('#search_filter_query').on('keydown', () => {
        $(this).removeClass('invalid');
      });
      $('#search_filter_label').on('keydown', () => {
        $(this).removeClass('invalid');
      });
    });
  }

  allAssetKeys() {
    const sectionAssets = Object.values(this.state.assets);

    if (!sectionAssets.length) { return []; }

    return sectionAssets.reduce((acc, val) => acc.concat(val), []).map((asset) => asset?.id);
  }

  allVisibleAssetKeys() {
    const { activeSection } = this.state;
    let assets = { ...this.state.assets };

    if (activeSection !== 'all') {
      assets = { [activeSection]: assets[activeSection] || [] };
    }

    const sectionAssets = Object.values(assets);
    if (!sectionAssets.length) { return []; }

    return sectionAssets.reduce((acc, val) => acc.concat(val), []).map((asset) => asset?.id);
  }

  allViewOnlyAssetKeys() {
    const { downloadRequestId } = this.props;

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');
    if (!viewOnlyAssetsEnabled || downloadRequestId) { return []; }

    const { assets } = this.state;

    const sectionAssets = Object.values(assets);
    if (!sectionAssets.length) { return []; }

    const reduced = sectionAssets.reduce((acc, val) => acc.concat(val), []);
    const filtered = reduced.filter((asset) => asset?.attributes?.view_only);

    return filtered.map((asset) => asset?.id);
  }

  allVisibleViewOnlyAssetKeys() {
    const { downloadRequestId } = this.props;

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');
    if (!viewOnlyAssetsEnabled || downloadRequestId) { return []; }

    const { activeSection } = this.state;
    let assets = { ...this.state.assets };

    if (activeSection !== 'all') {
      assets = { [activeSection]: assets[activeSection] || [] };
    }

    const sectionAssets = Object.values(assets);
    if (!sectionAssets.length) { return []; }

    const reduced = sectionAssets.reduce((acc, val) => acc.concat(val), []);
    const filtered = reduced.filter((asset) => asset?.attributes?.view_only);

    return filtered.map((asset) => asset?.id);
  }

  escapedSearchQuery(searchQuery, searchOperator) {
    return (searchQuery && (searchOperator === SearchOperatorOptions.Quoted || searchOperator === SearchOperatorOptions.Strict))
      ? `"${searchQuery.replace(/"/g, '\\"')}"`
      : searchQuery;
  }

  sectionShouldRender(sectionKey) {
    const { sections, editable } = this.props;
    const {
      assets,
      loading,
      searchQuery,
      filterStrings,
      activeSection: activeSectionKey,
      userViewOptions,
    } = this.state;
    const activeSection = sections.find((section) => section.section_key.toString() === activeSectionKey);
    const isActive = activeSection ? activeSection.section_key === sectionKey : false;
    const hasAssets = Boolean(assets[sectionKey]) && assets[sectionKey].length > 0;
    const hideWhenEmpty = userViewOptions.showEmptySections === false && !hasAssets;
    const noActiveNoSearch = !activeSection && !searchQuery && !filterStrings.length;

    return !userViewOptions?.showSections // <-- always show section pills as clickable when showSections === false
      || (editable && !hideWhenEmpty && (noActiveNoSearch || loading || hasAssets || isActive))
      || (!editable && hasAssets);
  }

  renderContent() {
    const {
      editable,
      printuiSectionId,
      sectionsToShow,
      authenticityToken,
      lazyLoadCards,
      libraryName,
      enableContactSheets,
      isPreview,
      downloadRequestId
    } = this.props;
    let { sections } = this.props;

    const {
      activeSection,
      assets,
      canvaApi,
      customFieldKeys,
      dependentCustomFields,
      entireSectionSelected,
      internalShareLinkExpired,
      filters,
      loading: allLoading,
      searchFilterOperators,
      searchQuery,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys,
      tasks,
      userViewOptions,
      windowDimensions
    } = this.state;

    const isTest = window.BF_Environment === 'test';
    const search = this.escapedSearchQuery(searchQuery, userViewOptions.searchOperator);

    if (internalShareLinkExpired) {
      return (
        <StandardAlert heading={<Trans>This shareable link has expired.</Trans>} />
      )
    }

    const returnSection = ({
      currentPage,
      index,
      loading,
      section,
      sectionAssets,
      sectionTasks,
      totalPages,
      total,
    }) => (
      <Section
        key={section.section_key}
        activeSection={activeSection}
        allAssetsSelected={entireSectionSelected.has(section.section_key)}
        assets={sectionAssets}
        authenticityToken={authenticityToken}
        canvaApi={canvaApi}
        currentPage={currentPage}
        customFieldKeys={customFieldKeys}
        dependentCustomFields={dependentCustomFields}
        downloadRequestId={downloadRequestId}
        editable={editable}
        enableContactSheets={enableContactSheets}
        isPreview={isPreview}
        isRendered={isTest ? true : index === 0}
        lazyLoadCards={lazyLoadCards}
        libraryName={libraryName}
        loading={allLoading || Boolean(loading)}
        onAssetProcessing={(quantity) => this.setState({ assetsProcessing: quantity })}
        onNewAssets={(assetData) => this.onNewAssets(section.section_key, assetData)}
        onUpdate={(options) => this.fetchSection(section.section_key, options)}
        printuiSectionId={printuiSectionId}
        searchQuery={filterLogic(filters, search, searchFilterOperators)}
        section={section}
        selectedAssetKeys={selectedAssetKeys}
        selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
        shiftSelect={this.shiftSelect}
        tasks={sectionTasks}
        toggleSelected={this.toggleSelected}
        totalAssets={total}
        totalPages={totalPages || 1}
        userViewOptions={userViewOptions}
        windowDimensions={windowDimensions}
      />
    );

    const activeAssets = assets?.[activeSection]
      || assets?.['search-results-assets']
      || assets?.['show-page-assets'];

    const showNoResults = (searchQuery?.length > 0 && this.allAssetKeys().length === 0)
      || (activeAssets?.length === 0 && !userViewOptions?.showSections && activeSection === 'all');

    if (!allLoading && showNoResults) {
      return (
        <div>
          <p className="no-results-copy">
            <Trans>No results found</Trans>
          </p>
        </div>
      );
    }

    if (!userViewOptions?.showSections && activeSection === 'all') {
      // when sectionless and the 'All' section pill is selected
      const name = searchQuery ? t`Search Results` : t`All Assets`;
      const section_key = searchQuery ? 'search-results-assets' : 'show-page-assets';

      return returnSection({
        index: 0,
        section: { name, section_key, default_asset_type: undefined, id: 0 },
        sectionAssets: assets[section_key],
        sectionTasks: tasks[section_key] || [],
        ...this.getSection(section_key),
      });
    }

    if (!userViewOptions?.showSections) {
      // when sectionless and a section pill is selected
      sections = sections.filter((section) => section.section_key === activeSection);
    }

    return sections.map((section, index) => {
      if (!editable && !sectionsToShow.includes(section.id)) return undefined;
      if (!this.sectionShouldRender(section.section_key)) return undefined;

      return returnSection({
        index,
        section,
        sectionAssets: assets[section.section_key] || [],
        sectionTasks: tasks[section.section_key] || [],
        ...this.getSection(section.section_key),
      });
    });
  }

  render() {
    const {
      approver,
      defaultSortOrder,
      editable,
      libraryName,
      paginatePer,
      parentBrandfolders,
      pinsDropdownSetting,
      requestDownloadName,
      requestDownloadRequestee,
      sections,
      sectionDropdownSetting,
      sectionsToShow,
    } = this.props;

    const {
      activeLabelKey,
      activeSection,
      activeSearchFilter,
      downloadAlertCallback,
      filters,
      filtersDrawerOpen,
      filterStrings,
      assetsProcessing,
      labels,
      lastVisited,
      loading,
      searchableThings,
      searchKey,
      searchFilterOperators,
      searchFilters,
      searchQuery,
      searchSubmitted,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys,
      showAdvancedDownloadModal,
      showDownloadAlert,
      showFullQuery,
      showWebToPrintModal,
      userViewOptions,
      windowDimensions,
    } = this.state;

    const { searchOperator, selectedUGTLocale, showAsGrid, showSections, sortOption } = userViewOptions;
    const listView = !showAsGrid;
    const filtersDrawerClass = filtersDrawerOpen !== "none" ? "filters-open" : "";
    const labelsFeatureClass = BFG.context.hasFeature("labels") && labels?.labelsResponse?.length
      ? "labels-feature-enabled"
      : '';

    const disabledSections = sections.filter((section) => (
      !this.sectionShouldRender(section.section_key)
    )).map((section) => section.section_key);

    let activeLabelName = null;
    if (labels.labelsFlat) {
      const label = labels.labelsFlat[activeLabelKey];
      activeLabelName = label ? label.name : null;
    }

    return (
      <SectionsContext.Provider
        value={{
          activeLabelKey,
          activeLabelName,
          addRemoveSelected: this.addRemoveSelected,
          editable,
          filtersDrawerOpen,
          labelsDrawerOpen: filtersDrawerOpen === 'labels',
          listView,
          parentBrandfolders,
          selectedAssetKeys,
          selectedViewOnlyAssetKeys,
          shiftSelect: this.shiftSelect,
          showSections,
          sortOption,
          submitSearchQuery: this.submitSearchQuery,
          toggleSelected: this.toggleSelected,
          windowDimensions,
        }}
      >
        <CustomDragLayer selectedAssetKeys={selectedAssetKeys} />
        <div className="custom_section_title" />
        {!BFG.showPageLite && (
          <SectionSort
            activeSection={activeSection}
            disabledSections={disabledSections}
            editable={editable}
            manageSectionsFeature={BFG.context.hasFeature("manage_sections")}
            sectionDropdownSetting={sectionDropdownSetting}
            sections={sections}
            sectionsToShow={sectionsToShow}
            showSections={showSections}
            toggleActiveSection={this.toggleActiveSection}
          />
        )}
        {(!BFG.showPageLite && (editable || searchFilters.length > 0)) && (
          <PinnedSearches
            activeSearchFilter={activeSearchFilter}
            editable={editable}
            pinsDropdownSetting={pinsDropdownSetting}
            searchFilters={searchFilters}
            searchQuery={searchQuery}
            searchSubmitted={searchSubmitted}
            submitSearchQuery={this.submitSearchQuery}
            updateSearchFilters={this.updateSearchFilters}
          />
        )}
        <div className={`brandfolder-assets-wrapper ${filtersDrawerClass} ${labelsFeatureClass}`} data-cx-customization={`brandfolder-assets-wrapper`}>
          {!BFG.showPageLite && (
            <FiltersDrawer
              activateLabel={this.activateLabel}
              activeLabelKey={activeLabelKey}
              filters={filters}
              filtersDrawerOpen={filtersDrawerOpen}
              labels={labels}
              loading={loading}
              searchFilterOperators={searchFilterOperators}
              searchableThings={searchableThings}
              selectedAssetKeys={selectedAssetKeys}
              selectedUGTLocale={selectedUGTLocale || BFG.locales.ugtLocaleDefault}
              toggleFiltersDrawer={this.toggleFiltersDrawer}
              updateFilters={this.updateFilters}
              updateSearchFilterOperator={this.updateSearchFilterOperator}
            />
          )}
          <div className="asset-content">
            {BFG.downloadRequestKey && (
              <ApproveRequestActions
                selectAll={this.selectAll}
              />
            )}
            {!BFG.showPageLite && activeLabelKey && BFG.context.hasFeature("labels") ? (
              <LabelsBreadcrumbs
                activateLabel={this.activateLabel}
                activeLabelKey={activeLabelKey}
                labelsFlat={labels.labelsFlat}
              />
            ) : ''}
            {!BFG.showPageLite && (
              // Note that the key here is important! Changing it causes the component to refresh,
              // which is how it receives new props, specifically the searchQuery when saved
              // searches are clicked. It's a little magical, but it works great!
              <>
                <SectionSearch
                  key={searchKey}
                  addRemovePinnedSearch={this.addRemovePinnedSearch} // TODO: don't rely on this
                  brandfolderKey={BF.fx.brandfolderID()}
                  editable={editable}
                  loading={loading}
                  searchFilters={searchFilters}
                  searchQuery={searchQuery}
                  searchSubmitted={searchSubmitted}
                  submitSearchQuery={this.submitSearchQuery}
                  toggleFiltersDrawer={this.toggleFiltersDrawer}
                  updateSearchQuery={this.updateSearchQuery}
                  updateUserViewOptions={this.updateUserViewOptions}
                  userViewOptions={userViewOptions}
                  windowDimensions={windowDimensions}
                />
                <div className="search-and-view-details">
                  <SearchDetails
                    filterStrings={filterStrings}
                    filters={filters}
                    resetFilters={this.resetFilters}
                    searchFilterOperators={searchFilterOperators}
                    searchOperator={searchOperator}
                    searchQuery={searchQuery}
                    showFullQuery={showFullQuery}
                    toggleShowFullQuery={() => this.setState((prevState) => ({ showFullQuery: !prevState.showFullQuery }))}
                  />
                  <ViewOptions
                    defaultSortOrder={defaultSortOrder}
                    paginatePer={paginatePer}
                    searchSubmitted={searchSubmitted}
                    updateUserViewOptions={this.updateUserViewOptions}
                    userViewOptions={userViewOptions}
                  />
                </div>
              </>
            )}
            <InternalShareBar allAssetKeys={new Set(this.allAssetKeys())} />
            {this.renderContent()}
            <BulkSelectBar
              activeLabelKey={activeLabelKey}
              addRemoveSelected={this.addRemoveSelected}
              allVisibleAssetKeys={this.allVisibleAssetKeys()}
              approver={approver}
              assetsProcessing={assetsProcessing}
              clearSelected={this.clearSelected}
              editable={editable}
              labels={labels}
              libraryName={libraryName}
              requestDownloadName={requestDownloadName}
              requestDownloadRequestee={requestDownloadRequestee}
              selectAllVisible={this.selectAllVisible}
              selectedAssetKeys={selectedAssetKeys}
              selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
              ugtLocale={userViewOptions.selectedUGTLocale || ''}
              windowDimensions={windowDimensions}
              userNotificationsPath={this.props.userNotificationsPath}
            />
            {BFG.context.hasFeature("web_to_print") && showWebToPrintModal && (
              <FetchControllersWrapper>
                <Web2PrintModal
                  assetKey={lastVisited.assetKey}
                  collectionSlug={Collection.slug || null}
                  launchedFromModal
                  resetShowModal={() => this.setState({ showWebToPrintModal: false })}
                />
              </FetchControllersWrapper>
            )}
            {BFG.context.hasFeature("cropping") && showAdvancedDownloadModal && (
              <AdvancedDownloadModal
                attachmentKey={lastVisited.attachmentKey}
                editable={editable}
                goBackToAsset={lastVisited.assetKey}
                resetShowModal={() => this.setState({ showAdvancedDownloadModal: false })}
                resource={{ type: this.resourceType.toLowerCase(), key: this.resourceKey }}
                shouldCloseOnOverlayClick={false}
              />
            )}
            <DownloadAlertDialog
              downloadAlert={this.props.downloadAlert}
              downloadCallback={downloadAlertCallback}
              open={showDownloadAlert}
              setOpen={(open) => this.setState({ showDownloadAlert: open })}
            />
          </div>
        </div>
        <div className={classnames("processing-loader-container",
          {
            active: assetsProcessing > 0,
            "bulk-select-bar-active": this.state.selectedAssetKeys.size > 0,
          })}
        >
          {assetsProcessing > 0 && (
            <ProcessingLoader
              assetQuantity={this.state.assetsProcessing}
              onClose={() => this.setState({ assetsProcessing: 0 })}
            />
          )}
        </div>
      </SectionsContext.Provider>
    );
  }
}

ShowPage.propTypes = {
  approver: PropTypes.bool,
  controlledCustomFieldsEnabled: PropTypes.bool,
  downloadAlert: PropTypes.string,
  editable: PropTypes.bool,
  libraryName: PropTypes.string,
  pinsDropdownSetting: PropTypes.bool,
  sectionDropdownSetting: PropTypes.bool,
  defaultSortOrder: PropTypes.string,
  isPreview: PropTypes.bool,
  requestDownloadName: PropTypes.string,
  requestDownloadRequestee: PropTypes.string,
  searchFilters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
  })),
  sections: PropTypes.arrayOf(PropTypes.shape({
    section_key: PropTypes.string,
  })),
  sectionsToShow: PropTypes.arrayOf(PropTypes.number),
  downloadRequestId: PropTypes.number,
  paginatePer: PropTypes.number.isRequired,
  printuiSectionId: PropTypes.number,
  lazyLoadCards: PropTypes.bool,
  enableContactSheets: PropTypes.bool
};

ShowPage.defaultProps = {
  approver: false,
  controlledCustomFieldsEnabled: false,
  defaultSortOrder: 'position ASC',
  downloadAlert: '',
  downloadRequestId: null,
  editable: false,
  enableContactSheets: false,
  isPreview: false,
  lazyLoadCards: true,
  libraryName: "Brandfolder",
  pinsDropdownSetting: false,
  printuiSectionId: null,
  requestDownloadName: '',
  requestDownloadRequestee: '',
  searchFilters: [],
  sectionDropdownSetting: false,
  sections: [],
  sectionsToShow: []
};

export default ShowPage;
