import {
  ButtonLooks,
  FontIcons,
  StandardAlert,
  StandardButton,
  StandardPopout
} from '@brandfolder/react';
import { plural, t, Trans } from '@lingui/macro';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import fetchJSON from '@api/api_helper';
import { postCsvPoller as csvFetch } from '@api/v4/assets/csv_metasheets';
import getAssetInfo from '@api/v4/private/brandfolders/bulkAssetsInfo';
import Tooltip from '@components/common/tooltip/main';
import { PeoplePageParamKeys } from '@components/people_tags/customPeopleTagTypes';
import { getArrayIncludesOtherArray, getFilteredArray } from '@helpers/arrays';
import download from '@helpers/download';
import { allowRemoveFromShareLink } from '@helpers/share-links';
import { getStandardPopoutLabels } from '@translations';

import { BulkShareLinkModal } from './bulk-share-link-modal/BulkShareLinkModal';
import { BulkWorkspaceModal } from './bulk-workspace-modal/BulkWorkspaceModal';
import BulkAction from './BulkActionComponent';
import BulkAddLabelModal from './BulkAddLabelModal';
import BulkAvailabilityModal from './BulkAvailabilityModal';
import BulkBoardModal from './BulkBoardModal';
import BulkCollectionModal from './BulkCollectionModal';
import { BulkCSVDownloadLimitDialog } from './BulkCSVDownloadLimitDialog';
import BulkDeleteModal from './BulkDeleteModal';
import { BulkDownloadLimitDialog } from './BulkDownloadLimitDialog';
import BulkMergeModal from './BulkMergeModal';
import BulkMoveModal from './BulkMoveModal';
import BulkRemoveLabelModal from './BulkRemoveLabelModal';
import { BulkShareLinkAssetRemovalModal } from './BulkShareLinkAssetRemovalModal';
import BulkShareModal from './BulkShareModal';
import BulkTagWrapper from './BulkTagWrapper';
import { BulkViewOnlyDialog } from './BulkViewOnlyDialog';
import DownloadAccessResponse from './DownloadAccessResponse';
import {
  bulkAddToCollectionsEnabled,
  bulkAddToEnabled,
  bulkAddToLabelsEnabled,
  bulkAddToShareLinksEnabled,
  bulkAddToSubcollectionsEnabled,
  bulkAddToWorkspacesEnabled,
  bulkApprovalsEnabled,
  bulkApproveDownloadEnabled,
  bulkAvailabilityEnabled,
  bulkCollectionRemovalEnabled,
  bulkDeleteEnabled,
  bulkDownloadEnabled,
  bulkMergeEnabled,
  bulkMoveEnabled,
  bulkPeopleTaggingEnabled,
  bulkRemoveLabelEnabled,
  bulkRequestDownloadEnabled,
  bulkShareEnabled,
  bulkTaggingEnabled,
  bulkViewOnlyEnabled
} from './helpers/bulk-action-enabled';
import { hasSomeViewOnlySelected } from './helpers/has-some-view-only-selected';
import RequestDownloadAccessModal from './RequestDownloadAccessModal';

import './styles/bulk_select_bar.scss';

const notifyFailure = () => Notify.create({
  title: t`Something went wrong. Try again soon!`,
  type: 'error'
});

class BulkSelectBar extends React.Component {
  state = {
    activeCdnTracers: null,
    designHuddleAssets: [],
    designHuddleAssetsExist: false,
    disableDownloadClick: false,
    expandedAddTo: false,
    expandedTag: false,
    hasSubCollections: false,
    mobileMenuExpanded: false,
    showModal: null,
    showDownloadPopout: false,
    selectedAssetsData: [],
    selectedAssetTypes: new Set(),
    selectedAssetsApproved: false,
  };

  componentDidMount() {
    if ($('body').hasClass('desktop-view') || $('body').hasClass('desktop-narrow-view')) {
      this.determineBrowserWidth(); // execute on page load
      window.addEventListener('resize', this.determineBrowserWidth);
    }
    this.determineSubCollectionsPresent();
  }

  componentDidUpdate(prevProps) {
    const assetsProcessingDone = this.props.assetsProcessing < prevProps.assetsProcessing;
    const selectedAssetKeysDifferent = this.props.selectedAssetKeys.size !== prevProps.selectedAssetKeys.size;
    if ((assetsProcessingDone || selectedAssetKeysDifferent) && this.props.selectedAssetKeys.size) {
      // comparing previous and current props.selectedAssetKeys.size did not behave as expected
      // componentDidUpdate did not catch a difference in previous and current
      // props.selectedAssetKeys.size on asset upload
      // so using props.assetsProcessing here to trigger this
      this.getSelectedAssetsData();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.determineBrowserWidth);
  }

  getCurrentCollection = async () => (
    fetchJSON(`/api/v4/collections/${BFG.resource.key}?include=subcollections`));

  // subCollections will indicate that the Add to [Sub]Collections option should be available
  // when a Collection is the resource
  determineSubCollectionsPresent = () => {
    if (BFG?.resource?.type !== "collection") { return; }
    this.getCurrentCollection().then((response) => {
      const subCollectionsPresent = !!response?.included?.find((includedObject) => includedObject?.type === "collections");
      if (subCollectionsPresent) {
        this.setState({ hasSubCollections: true });
      }
    });
  }

  checkForDesignHuddleAsset = () => {
    if (this.state.designHuddleAssets.some((asset) => asset !== null)) {
      this.setState({
        designHuddleAssetsExist: true
      });
    } else {
      this.setState({
        designHuddleAssetsExist: false
      });
    }
  }

  getSelectedAssetsData = () => {
    if (this.props.orgSearchPage) { return undefined; }

    const { selectedAssetKeys } = this.props;
    if (selectedAssetKeys.size > 0) {
      getAssetInfo({
        brandfolderKey: BF.fx.brandfolder().key,
        assetKeys: Array.from(selectedAssetKeys),
      }).then(({ types, assets, approved, active_cdn_tracers }) => {
        this.setState({
          designHuddleAssets: assets.map((asset) => asset.attributes.design_huddle_editor_link),
          selectedAssetTypes: new Set(types),
          activeCdnTracers: active_cdn_tracers,
          selectedAssetsData: assets,
          selectedAssetsApproved: approved
        });
        this.checkForDesignHuddleAsset();
      });
    } else {
      this.setState({
        designHuddleAssets: [],
        selectedAssetsData: [],
        selectedAssetTypes: new Set(),
        selectedAssetsApproved: false,
      });
    }
    return undefined;
  }

  resetShowModal = () => {
    this.setState({ showDownloadPopout: false, showModal: null });
  }

  determineBrowserWidth = () => {
    const $windowWidth = $(window).width();
    const $body = $('body');

    if ($windowWidth <= 935 && $body.hasClass('desktop-view')) {
      $body.removeClass('desktop-view').addClass('desktop-narrow-view');
    } else if ($windowWidth > 935 && $body.hasClass('desktop-narrow-view')) {
      $body.removeClass('desktop-narrow-view').addClass('desktop-view');
    }
  }

  closeBulkSelectBar = () => {
    this.setState({ mobileMenuExpanded: false, showDownloadPopout: false });
    this.props.addRemoveSelected([]);
  }

  renderModal = () => {
    const {
      activeLabelKey,
      addRemoveSelected,
      labels,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys,
      libraryName,
      requestDownloadName,
      requestDownloadRequestee,
      ugtLocale,
    } = this.props;

    const {
      selectedAssetsData,
      selectedAssetTypes,
      showModal
    } = this.state;

    switch (showModal) {
      case "BulkShareModal":
        return (
          <BulkShareModal
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkTagWrapper":
        return (
          <BulkTagWrapper
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
            ugtLocale={ugtLocale}
          />
        );
      case "BulkCollectionModal":
        return (
          <BulkCollectionModal
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkSubCollectionModal":
        return (
          <BulkCollectionModal
            closeModal={this.resetShowModal}
            isSubCollectionModal
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkAvailabilityModal":
        return (
          <BulkAvailabilityModal
            activeCdnTracers={this.state.activeCdnTracers}
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkRemoveLabelModal":
        return (
          <BulkRemoveLabelModal
            activeLabelKey={activeLabelKey}
            addRemoveSelected={addRemoveSelected}
            closeModal={this.resetShowModal}
            labels={labels}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkAddLabelModal":
        return (
          <BulkAddLabelModal
            activeLabelKey={activeLabelKey}
            addRemoveSelected={addRemoveSelected}
            closeModal={this.resetShowModal}
            labelTree={labels.labelsTree}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkDeleteModal":
        return (
          <BulkDeleteModal
            addRemoveSelected={addRemoveSelected}
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkBoardModal":
        return (
          <BulkBoardModal
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      case "BulkMergeModal":
        return (
          <BulkMergeModal
            addRemoveSelected={addRemoveSelected}
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
            selectedAssetsData={selectedAssetsData}
          />
        );
      case "BulkMoveModal":
        return (
          <BulkMoveModal
            addRemoveSelected={addRemoveSelected}
            closeModal={this.resetShowModal}
            libraryName={libraryName}
            moveBrandfolderEnabled={!this.state.designHuddleAssetsExist}
            selectedAssetKeys={selectedAssetKeys}
            selectedAssetTypes={selectedAssetTypes}
          />
        );
      case "RequestDownloadAccess":
        return (
          <RequestDownloadAccessModal
            closeModal={this.resetShowModal}
            selectedAssetKeys={selectedAssetKeys}
            selectedViewOnlyAssetKeys={selectedViewOnlyAssetKeys}
          />
        );
      case "DownloadAccessResponse":
        return (
          <DownloadAccessResponse
            closeModal={this.resetShowModal}
            requestDownloadName={requestDownloadName}
            requestDownloadRequestee={requestDownloadRequestee}
            selectedAssetKeys={selectedAssetKeys}
          />
        );
      default:
        return null;
    }
  }

  handleBulkAction = (modalName, onClick = undefined) => {
    if (onClick) {
      onClick();
    } else if (modalName) {
      this.setState({ showDownloadPopout: false, showModal: `${modalName}` })
    }
  }

  bulkAction = (modalName, icon, copy, withText = false, id = '', onClick = undefined, children = null) => {
    const { disableDownloadClick, showDownloadPopout } = this.state;
    const isRequestDownload = id === 'bulk-request-download';
    const isDownloadPopout = id === 'bulk-download-popout';

    let cssClasses = '';
    if (withText) {
      cssClasses += ' with-text';
    }
    if (isRequestDownload) {
      cssClasses += ' request-download';
    }
    if (isDownloadPopout) {
      cssClasses += ' download-action';
    }
    if (disableDownloadClick) {
      cssClasses += ' disabled-section';
    }

    const copyMap = {
      'Approve Selected Assets': t`Approve Selected Assets`,
      Delete: t`Delete`,
      Download: t`Download`,
      'Mark as view only': t`Mark as view only`,
      Merge: t`Merge`,
      Move: t`Move`,
      'Remove from Share Link': t`Remove from Share Link`,
      'Remove Label': t`Remove Label`,
      'Request Download': t`Request Download`,
      'Schedule Availability': t`Schedule Availability`,
      Share: t`Share`,
      Tag: t`Tag`
    };

    return (
      <Tooltip
        className={`${copy.split(' ').join('-')}-tooltip-component`}
        offset={`{'top': -20}`}
        tooltipContent={copyMap[copy]}
        tooltipId={`${copy.split(' ').join('-')}-tooltip`}
        type="light"
      >
        <div
          aria-controls={isDownloadPopout ? id : undefined}
          aria-expanded={isDownloadPopout ? showDownloadPopout : undefined}
          aria-haspopup={isDownloadPopout ? "dialog" : undefined}
          className={`bulk-bar-item hover-item${cssClasses}`}
          onClick={() => this.handleBulkAction(modalName, onClick)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              this.handleBulkAction(modalName, onClick)
            }
          }}
          role="button"
          tabIndex={0}
        >
          <span
            aria-hidden={withText}
            aria-label={withText ? '' : `${copyMap[copy]}`}
            className={`icon ${icon}`}
          />
          <span className="action-text">{copyMap[copy]}</span>
          {children}
        </div>
      </Tooltip>
    )
  };

  handleDownloadButton = () => {
    const { selectedAssetKeys } = this.props;

    const hasOver1000AssetsSelected = selectedAssetKeys.size > 1000;

    if (hasOver1000AssetsSelected) {
      this.setState({
        showModal: 'BulkDownloadLimit',
        showDownloadPopout: false
      });
    } else {
      this.bulkDownloadAlert(this.bulkDownloadAssets);
    }
  }

  handleCSVDownloadButton = () => {
    const { selectedAssetKeys } = this.props;

    const hasOver100kAssetsSelected = selectedAssetKeys.size > 100000;

    if (hasOver100kAssetsSelected) {
      this.setState({
        showModal: 'BulkCSVDownloadLimit',
        showDownloadPopout: false
      });
    } else {
   this.bulkDownloadAlert(this.bulkCSVDownloadAssets); }
  }

  hasOnlyViewOnlySelected = () => {
    const { selectedAssetKeys, selectedViewOnlyAssetKeys } = this.props;

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

    return viewOnlyAssetsEnabled && selectedViewOnlyAssetKeys.size > 0 && getArrayIncludesOtherArray(Array.from(selectedAssetKeys), Array.from(selectedViewOnlyAssetKeys));
  }

  renderDownloadPopoutContent = () => {
    const { selectedAssetKeys, selectedViewOnlyAssetKeys, orgSearchPage } = this.props;
    const { disableDownloadClick } = this.state;

    return (
      <>
        {hasSomeViewOnlySelected({ selectedAssetKeys, selectedViewOnlyAssetKeys }) && (
          <StandardAlert icon={FontIcons.Show}>
            <Trans>
              One or more of your selected assets are view only and will not be included in the download.
            </Trans>
          </StandardAlert>
        )}
        <StandardButton
          fullWidth
          look={ButtonLooks.Default}
          onClick={!disableDownloadClick ? () => this.handleDownloadButton() : undefined}
          startIcon={FontIcons.Download}
        >
          <Trans>Download as ZIP</Trans>
        </StandardButton>
        {!orgSearchPage && (BFG.context.hasFeature('csv_metasheets') && ['collaborator', 'admin', 'owner'].indexOf(BFG.currentUser.role) > -1) && (
          <StandardButton
            fullWidth
            look={ButtonLooks.Default}
            onClick={!disableDownloadClick ? () => this.handleCSVDownloadButton() : undefined}
            startIcon={FontIcons.Download}
          >
            <Trans>Download as CSV</Trans>
          </StandardButton>
        )}
        {!orgSearchPage && (
          <StandardButton
            fullWidth
            look={ButtonLooks.Default}
            onClick={!disableDownloadClick ? () => BF.BulkAction.bulk_advanced_download_show(this) : undefined}
            startIcon={FontIcons.Settings}
          >
            <Trans>Advanced options</Trans>
          </StandardButton>
        )}
      </>
    )
  }

  bulkDownloadPopout = () => {
    const { disableDownloadClick, showDownloadPopout } = this.state;

    const id = "bulk-download-popout";

    return (
      <StandardPopout
        className='view-only-download-action'
        content={this.renderDownloadPopoutContent()}
        focusLock={false}
        id={id}
        labels={getStandardPopoutLabels()}
        open={showDownloadPopout}
        setOpen={() => this.setState({ showDownloadPopout: false })}
      >
        {this.bulkAction(
          undefined,
          `bff-${FontIcons.Download}`,
          'Download',
          true,
          id,
          !disableDownloadClick ? () => this.setState({ showDownloadPopout: !showDownloadPopout }) : undefined
        )}
      </StandardPopout>
    )
  }

  bulkActionDownload = () => {
    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

    if (viewOnlyAssetsEnabled && this.hasOnlyViewOnlySelected()) {
      return null;
    }

    return this.bulkDownloadPopout();
  }

  csvPoller = (downloadKey, resourceType, resourceKey, counter) => {
    const downloadIndicatorClass = '.download_progress_indicator';
    const $downloadIndicator = $(downloadIndicatorClass);
    const originalText = t`Hang tight! We're preparing your download...`;
    $downloadIndicator.removeClass('csv').html(`<span class="loading">${originalText}</span>`);

    const slug = BF.fx.resource().slug || 'share_manifest';
    const options = { slug, downloadKey, resourceType, resourceKey, params: { manifest_digest: BFG.manifestDigest } };
    const resourceName = BF.fx.resource().name;

    csvFetch(options)
      .then(({ response, json }) => {
        if (response.status === 200) {
          this.setState({ disableDownloadClick: false });
          download(json.download_url, { filename: `${resourceName}-assets.csv`, filetype: 'text/csv' });

          setTimeout(() => {
            $downloadIndicator.css('visibility', 'hidden').removeClass('active bulk-download');
          }, 1000);

          Notify.create({
            title: t`Downloaded`,
            type: "success"
          });
        }
        else if (response.status === 202 && counter < 10) {
          // poll for 10 seconds
          setTimeout(() => { this.csvPoller(options.downloadKey, options.resourceType, options.resourceKey, counter + 1); }, 1000);
        }
        else if (response.status === 202 && counter >= 10) {
          this.setState({ disableDownloadClick: false });
          $downloadIndicator.addClass('csv');
          const processing = t`We're processing your download. It will be available soon under`;

          const notifications = t`Notifications`;
          const notificationsLink = `<a href="${this.props.userNotificationsPath}">${notifications}</a>`;

          const close = t`Close`;

          const onClickVisibility = `document.querySelectorAll('${downloadIndicatorClass}')[0].style.visibility = 'hidden';`;
          const onClickRemoveCss = `document.querySelectorAll('${downloadIndicatorClass}')[0].classList.remove('active','bulk-download');`;

          const closeButton = `<button aria-label="${close}" class="close" onclick="${onClickVisibility}${onClickRemoveCss}"><span class="bff-close"></span></button>`;
          $downloadIndicator.html(`<span class="loading"><span>${processing}${notificationsLink}.</span>${closeButton}</span>`);
        } else {
          notifyFailure()
        }
      })
      .catch((error) => {
        notifyFailure()
        console.error(error);
      });
  };

  getAssetKeysToDownload = () => {
    const { selectedAssetKeys, selectedViewOnlyAssetKeys } = this.props;

    const viewOnlyAssetsEnabled = BFG.hasFeature('view_only_assets');

    // if a resource that's NOT view only, only assets marked as view only are request download
    if (viewOnlyAssetsEnabled && !BFG.manifest?.downloadRequestId) {
      return getFilteredArray(Array.from(selectedAssetKeys), Array.from(selectedViewOnlyAssetKeys));
    } else {
      // if resource that's view only, all assets are request download
      return Array.from(selectedAssetKeys);
    }
  }

  bulkCSVDownloadAssets = () => {
    this.setState({ disableDownloadClick: true });

    const $downloadIndicator = $('.download_progress_indicator');
    const originalText = t`Hang tight! We're preparing your download...`;
    $downloadIndicator.html(`<span class="loading">${originalText}</span>`);

    $downloadIndicator.addClass('active bulk-download').css('visibility', 'visible');

    const slug = BF.fx.resource().slug || 'share_manifest';
    const { ugtLocale } = this.props;
    const ugtLocaleParam = ugtLocale ? `?ugt_locale=${ugtLocale}&queue_priority=high` : '?queue_priority=high';

    const url = `/${slug}/assets/csv/download_assets${ugtLocaleParam}`;
    const resourceType = BF.fx.resource().type;
    const resourceKey = BF.fx.resource().key;

    $.ajax({
      url,
      type: 'POST',
      data: {
        asset_keys: this.getAssetKeysToDownload(),
        resource_type: resourceType,
        resource_key: resourceKey,
      },
      context: this,
      beforeSend: (xhr) => {
        xhr.setRequestHeader('Authorization', `Bearer ${BF_Token}`);
      },
      success: (response, message, http) => {
        if (http.status === 202) {
          this.csvPoller(response.download_key, response.resource_type, response.resource_key, 0);
        } else {
          $downloadIndicator.css('visibility', 'hidden').removeClass('active bulk-download');
        }
      },
      error: () => {
        this.setState({ disableDownloadClick: false });
        notifyFailure()
        $downloadIndicator.css('visibility', 'hidden').removeClass('active bulk-download');
      }
    });
  };

  bulkDownloadAssets = () => {
    this.setState({ disableDownloadClick: true });

    const { selectedAssetKeys } = this.props;
    const $downloadIndicator = $('.download_progress_indicator');
    const originalText = t`Hang tight! We're preparing your download...`;
    $downloadIndicator.html(`<span class="loading">${originalText}</span>`);

    // overriding default translation to get above bulk select bar by adding class 'bulk-download'
    $downloadIndicator.addClass('active bulk-download').css('visibility', 'visible');

    const url = BF_Manifest
      ? `/api/v3/share_manifests/${BF_Manifest}/assets/download?queue_priority=high`
      : "/api/v3/assets/download?queue_priority=high";

    $.ajax({
      url,
      type: 'POST',
      data: {
        asset_keys: this.getAssetKeysToDownload()
      },
      context: this,
      beforeSend: (xhr) => { xhr.setRequestHeader('Authorization', `Bearer ${BF_Token}`); },
      success: (response) => {
        this.setState({ disableDownloadClick: false });
        if (response.data && !!response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
          setTimeout(() => {
            $downloadIndicator.css('visibility', 'hidden').removeClass('active bulk-download');
          }, 1000);
          const fileCount = parseInt(response.data.attachment_count || response.data.total_count, 10);
          const successMessage = plural(fileCount, {
            one: `Downloaded ${fileCount} asset`,
            other: `Downloaded ${fileCount} assets`
          });
          Notify.create({
            title: successMessage,
            type: "success"
          });
        } else {
          BF.BulkAction.result_poller(url.replace('download', 'bulk-convert-download-poller'), response.data, 500);
        }
      },
      error: () => {
        this.setState({ disableDownloadClick: false });
        notifyFailure()
        $downloadIndicator.css('visibility', 'hidden').removeClass('active bulk-download');
      },
      complete: () => {
        // Create asset download events
        selectedAssetKeys.forEach((assetKey) => {
          Insight.createEvent('downloaded', assetKey, 'asset');
        });
      }
    });
  }

  bulkDownloadAlert = (downloadCallback) => {
    if (BFG.downloadAlertEnabled) {
      BF.fx.dispatchWindowEvent('launchDownloadAlert', null, {
        downloadCallback
      });
    } else {
      downloadCallback();
    }
  }

  bulkActionComponent(actionType) {
    const { selectedAssetKeys, approver, addRemoveSelected } = this.props;
    const { activeCdnTracers, selectedAssetsApproved } = this.state;

    return (
      <BulkAction
        actionType={actionType}
        activeCdnTracers={activeCdnTracers}
        addRemoveSelected={addRemoveSelected}
        approver={approver}
        assetsApproved={selectedAssetsApproved}
        resetShowModal={this.resetShowModal}
        selectedAssetKeys={selectedAssetKeys}
      />
    );
  }

  bulkActionAddMobile(actions) {
    const { expandedAddTo } = this.state;

    return (
      <CSSTransition
        in={expandedAddTo}
        timeout={300}
        unmountOnExit
      >
        <ul className="show-touchscreen add-to-options">
          {
            actions.map((action) => {
              const cssClassName = action.name.split(' ').join('-');
              return (
                <li
                  key={`flyout-button-mobile-${cssClassName}`}
                  className="add-to-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ showDownloadPopout: false, showModal: action.modal });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <Trans>{action.name}</Trans>
                </li>
              );
            })
          }
        </ul>
      </CSSTransition>
    );
  }

  bulkActionAddDesktop(actions) {
    return (
      <div className="hide-touchscreen">
        <div className="download-options-container">
          {
            actions.map((action) => {
              const cssClassName = action.name.split(' ').join('-');
              return (
                <div
                  key={`flyout-button-${cssClassName}`}
                  className={`download-container hover-item add-to-${cssClassName}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ showDownloadPopout: false, showModal: action.modal });
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <h4 className="download-actions">
                    <Trans>Add to {action.name}</Trans>
                  </h4>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }

  bulkTagActions() {
    const hasCustomFields = BFG.context.hasFeature('custom_fields');
    const BulkTag = {
      name: t`Bulk tag`,
      icon: 'icon-tag',
      showModal: true
    }
    const BulkCustomFields = {
      name: t`Bulk custom fields`,
      icon: 'icon-custom-fields',
      showModal: true
    }
    const BulkPeopleTag = {
      name: t`Tag people`,
      icon: 'icon-people',
      showModal: false
    }
    const bulkTagActions = [BulkTag, BulkPeopleTag];
    hasCustomFields ? bulkTagActions.unshift(BulkCustomFields) : null;
    return bulkTagActions;
  }

  bulkActionTagDesktop(tagActions) {
    const selectedAssetKeysAsQueryParams = [...this.props.selectedAssetKeys].join(', ');

    return (
      <div className="hide-touchscreen">
        <div className="download-options-container">
          {
            tagActions.map((action) => {
              const cssClassName = action.name.split(' ').join('-');
              if (action.showModal) {
                return (
                  <div
                    key={`flyout-button-${cssClassName}`}
                    className={`download-container hover-item add-to-${cssClassName}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showDownloadPopout: false, showModal: 'BulkTagWrapper' });
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <h4 className="download-actions">{`${action.name}`}</h4>
                  </div>
                )
              } else {
                return (
                  <div
                    key={`flyout-button-${cssClassName}`}
                    className={`download-container hover-item add-to-${cssClassName}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showDownloadPopout: false });
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <a href={
                      `/${BFG.brandfolder_slug}/people_tags?${PeoplePageParamKeys.AssetIds}=${selectedAssetKeysAsQueryParams}`
                    }>
                      <h4 className="download-actions">{`${action.name}`}</h4>
                    </a>
                  </div>
                )
              }
            })
          }
        </div>
      </div>
    );
  }

  bulkTagFlyout(actions) {
    const { expandedTag } = this.state;
    return (
      <>
        <div
          className="bulk-bar-item with-text download-action add-to hover-item action-text"
          onClick={() => this.setState({ expandedTag: !expandedTag })}
          role="button"
          tabIndex={0}
        >
          <span aria-hidden="true" className="icon bff-tag" />
          <h4 className="tag-text action-text">
            <Trans>Tag</Trans>
          </h4>
          <div className="show-touchscreen">
            {
              expandedTag ? (
                <span aria-hidden="true" className="icon bff-caret-up" />
              ) : (
                <span aria-hidden="true" className="icon bff-caret-down" />
              )
            }
          </div>
          {this.bulkActionTagDesktop(actions)}
        </div>
        {this.bulkActionAddMobile(actions)}
      </>
    );
  }

  // TODO
  // Make this, and possibly all the other buk actions a separate component
  bulkActionFlyout(actions) {
    const { expandedAddTo } = this.state;
    return (
      <>
        <div
          className="bulk-bar-item with-text download-action add-to hover-item action-text"
          onClick={() => this.setState({ expandedAddTo: !expandedAddTo })}
          role="button"
          tabIndex={0}
        >
          <span aria-hidden="true" className="icon bff-plus" />
          <h4 className="download-text action-text">
            <Trans>Add to</Trans>
          </h4>
          <div className="show-touchscreen">
            {
              expandedAddTo ? (
                <span aria-hidden="true" className="icon bff-caret-up" />
              ) : (
                <span aria-hidden="true" className="icon bff-caret-down" />
              )
            }
          </div>
          {this.bulkActionAddDesktop(actions)}
        </div>
        {this.bulkActionAddMobile(actions)}
      </>
    );
  }

  // determine if we need a flyout of options or just one button for 'Add To'
  addToActionsRuleset() {
    const { editable } = this.props;
    const { hasSubCollections } = this.state;
    const showNone = !bulkAddToEnabled();
    if (showNone) return [];

    const boards = {
      name: t`Boards`,
      icon: 'bff-boards',
      modal: 'BulkBoardModal'
    };

    const collections = {
      name: t`Collections`,
      icon: 'bff-plus',
      modal: 'BulkCollectionModal'
    };

    const subCollections = {
      name: t`Subcollections`,
      icon: 'bff-plus',
      modal: 'BulkSubCollectionModal'
    };

    const labels = {
      name: t`Labels`,
      icon: 'bff-label',
      modal: 'BulkAddLabelModal'
    };

    const shareLinks = {
      name: t`Share Link`,
      icon: 'bff-share',
      modal: 'BulkShareLinkModal'
    };

    const workspaces = {
      name: t`Workspaces`,
      icon: 'bff-workspace',
      modal: 'BulkWorkspaceModal'
    };

    return [
      ...bulkAddToCollectionsEnabled({ editable }) ? [collections] : [],
      ...bulkAddToSubcollectionsEnabled({ editable, hasSubCollections }) ? [subCollections] : [],
      ...bulkAddToWorkspacesEnabled({ editable }) ? [workspaces] : [],
      boards,
      ...bulkAddToLabelsEnabled({ editable }) ? [labels] : [],
      ...bulkAddToShareLinksEnabled() ? [shareLinks] : []
    ];
  }

  renderBulkSelectActions() {
    const {
      allVisibleAssetKeys,
      selectAllVisible,
      selectedAssetKeys,
    } = this.props;

    const visibleAssetKeysSelected = allVisibleAssetKeys.filter((assetKey) => (selectedAssetKeys.has(assetKey)));
    const allClass = visibleAssetKeysSelected.length === allVisibleAssetKeys.length
      ? 'all-selected'
      : '';
    const amountOfAssetsSelected = plural(selectedAssetKeys.size, {
      one: `${selectedAssetKeys.size} selected`,
      other: `${selectedAssetKeys.size} selected`
    });

    return (
      <div className="select-actions-container">
        <div className="bulk-bar-item select-qty">
          <h4 className="select-text-faded">
            {amountOfAssetsSelected}
          </h4>
        </div>
        <div className="bulk-bar-item select-title">
          <h4 className="select-text-faded">
            <Trans>Select</Trans>:
          </h4>
        </div>
        <div
          className={`bulk-bar-item hover-item select-all ${allClass}`}
          onClick={() => selectAllVisible()}
          role="button"
          tabIndex={0}
        >
          <h4 className="select-text">
            <Trans>All Visible</Trans>
          </h4>
        </div>
        <div
          className="bulk-bar-item hover-item select-none"
          onClick={() => this.closeBulkSelectBar()}
          role="button"
          tabIndex={0}
        >
          <h4 className="select-text">
            <Trans>None</Trans>
          </h4>
        </div>
      </div>
    );
  }

  renderModals = () => {
    const {
      showModal
    } = this.state;

    const {
      clearSelected,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys,
      windowDimensions,
    } = this.props;

    // modals rendered here use the brandfolder library modal component
    // these modals are rendered here because using the switch statement to render them
    // breaks the animation for the modals fading out
    return (
      <>
        <BulkWorkspaceModal
          assetKeys={Array.from(selectedAssetKeys)}
          open={showModal === "BulkWorkspaceModal"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
        <BulkShareLinkAssetRemovalModal
          assetKeys={Array.from(selectedAssetKeys)}
          open={showModal === "BulkShareLinkAssetRemoval"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
        <BulkShareLinkAssetRemovalModal
          assetKeys={Array.from(selectedAssetKeys)}
          galleryView={true}
          open={showModal === "BulkShareLinkAssetRemovalGalleryView"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
        <BulkShareLinkModal
          assetKeys={Array.from(selectedAssetKeys)}
          open={showModal === "BulkShareLinkModal"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
          windowDimensions={windowDimensions}
        />
        <BulkDownloadLimitDialog
          clearSelected={() => {
            clearSelected();
            this.resetShowModal();
          }}
          open={showModal === "BulkDownloadLimit"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
        <BulkCSVDownloadLimitDialog
          clearSelected={() => {
            clearSelected();
            this.resetShowModal();
          }}
          open={showModal === "BulkCSVDownloadLimit"}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
        <BulkViewOnlyDialog
          open={showModal === "BulkViewOnlyDialog"}
          selectedAssetKeys={Array.from(selectedAssetKeys)}
          selectedViewOnlyAssetKeys={Array.from(selectedViewOnlyAssetKeys)}
          setOpen={(isOpen) => { if (!isOpen) this.resetShowModal(); }}
        />
      </>
    );
  }

  renderBulkActionToolbar() {
    const {
      activeLabelKey,
      approver,
      displayDownload,
      editable,
      orgSearchPage,
      selectedAssetKeys,
      selectedViewOnlyAssetKeys
    } = this.props;
    const { selectedAssetTypes } = this.state;
    const mobileClassMenu = this.state.mobileMenuExpanded ? 'mobile-expanded' : '';
    const mobileMenuPromptClass = this.state.mobileMenuExpanded ? 'mobile-prompt-hidden' : '';
    const addToActions = this.addToActionsRuleset();
    const tagActions = this.bulkTagActions();
    /* eslint-disable no-nested-ternary, no-undef */

    return (
      <>
        <div className={`bulk-actions-bar active ${mobileClassMenu}`}>
          <div className={`mobile-expand-prompt hide-desktop ${mobileMenuPromptClass}`}>
            {this.renderBulkSelectActions()}
            <div
              className="view-bulk-select-bar hover-item"
              onClick={() => this.setState((prevState) => ({
                mobileMenuExpanded: !prevState.mobileMenuExpanded,
                showDownloadPopout: false
              }))}
              role="button"
              tabIndex={0}
            >
              <span className="bff-ellipsis icon" />
              <h4 className="action-text">
                <Trans>More Actions</Trans>
              </h4>
            </div>
          </div>

          {this.renderBulkSelectActions()}
          <div className="bulk-actions-container">
            {bulkApprovalsEnabled({ approver, editable }) && this.bulkActionComponent('BulkApproval')}
            {bulkAvailabilityEnabled({ editable }) && (
              this.bulkAction('BulkAvailabilityModal', 'bff-clock', 'Schedule Availability')
            )}
            {bulkViewOnlyEnabled({ editable }) && (
              this.bulkAction('BulkViewOnlyDialog', 'bff-show', 'Mark as view only')
            )}
            {bulkMoveEnabled({
              editable,
              selectedAssetTypes
            }) && (this.bulkAction('BulkMoveModal', 'bff-move', 'Move'))}
            {bulkRemoveLabelEnabled({ activeLabelKey, editable }) && (
              this.bulkAction('BulkRemoveLabelModal', 'bff-label', 'Remove Label')
            )}
            {bulkDeleteEnabled({ editable }) && this.bulkAction('BulkDeleteModal', 'bff-trash', 'Delete')}
            {bulkPeopleTaggingEnabled({ editable }) && this.bulkTagFlyout(tagActions)}
            {bulkTaggingEnabled({ editable }) && this.bulkAction('BulkTagWrapper', 'bff-tag', 'Tag', true)}
            {bulkMergeEnabled({
              designHuddleAssetsExist: this.state.designHuddleAssetsExist,
              editable,
              selectedAssetTypes,
              selectedAssetKeys
            }) && this.bulkAction('BulkMergeModal', 'bff-merge', 'Merge', true)}
            {addToActions.length > 1 && this.bulkActionFlyout(addToActions)}
            {addToActions.length === 1 && (
              this.bulkAction(addToActions[0].modal, addToActions[0].icon, addToActions[0].name, true)
            )}
            {bulkCollectionRemovalEnabled({ editable }) && this.bulkActionComponent('BulkCollectionRemoval')}
            {bulkShareEnabled({ editable }) && this.bulkAction('BulkShareModal', 'bff-share', 'Share', true)}
            {bulkRequestDownloadEnabled({ orgSearchPage, selectedAssetKeys, selectedViewOnlyAssetKeys }) && (
              this.bulkAction(
                'RequestDownloadAccess',
                'bff-request-download',
                'Request Download',
                true,
                'bulk-request-download'
              )
            )}
            {bulkApproveDownloadEnabled() && (
              this.bulkAction('DownloadAccessResponse', 'bff-approve', 'Approve Selected Assets', true)
            )}
            {allowRemoveFromShareLink(
              BFG.currentUser?.role,
              BFG.currentUser?.user_id,
              BFG.manifest?.userKey,
              BFG.currentUser?.su
            ) && this.bulkAction(
              this.props.shareLinksGalleryView ? 'BulkShareLinkAssetRemovalGalleryView' : 'BulkShareLinkAssetRemoval',
              'bff-trash',
              "Remove from Share Link",
              true
            )}
            {bulkDownloadEnabled({ displayDownload, editable }) && this.bulkActionDownload()}
          </div>
          <div
            className="close-container hover-item hide-touchscreen"
            onClick={() => this.closeBulkSelectBar()}
            role="button"
            tabIndex={0}
          >
            <span className="bff-close icon" />
            <h4 className="hide-desktop close-text">
              <Trans>Deselect All & Close</Trans>
            </h4>
          </div>
          <div
            className="close-container hover-item hide-desktop"
            onClick={() => this.setState((prevState) => ({
              mobileMenuExpanded: !prevState.mobileMenuExpanded,
              showDownloadPopout: false
            }))}
            role="button"
            tabIndex={0}
          >
            <h4 className="hide-desktop close-text">
              <Trans>Hide</Trans>
            </h4>
          </div>
        </div>
        {/* BF modals rendered here, outside of bulk select bar elements to avoid style inheritance issues */}
        {this.renderModals()}
      </>
    );
    /* eslint-enable no-nested-ternary, no-undef */
  }

  render() {
    return (
      <div>
        {this.renderModal()}
        {this.props.selectedAssetKeys.size > 0
          ? this.renderBulkActionToolbar()
          : <div className="bulk-actions-bar" />}
      </div>
    );
  }
}

BulkSelectBar.propTypes = {
  activeLabelKey: PropTypes.string,
  addRemoveSelected: PropTypes.func.isRequired,
  allVisibleAssetKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  approver: PropTypes.bool.isRequired,
  assetsProcessing: PropTypes.number,
  clearSelected: PropTypes.func.isRequired,
  displayDownload: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  labels: PropTypes.shape({}),
  libraryName: PropTypes.string,
  orgSearchPage: PropTypes.bool,
  requestDownloadName: PropTypes.string,
  requestDownloadRequestee: PropTypes.string,
  selectAllVisible: PropTypes.func.isRequired,
  selectedAssetKeys: PropTypes.instanceOf(Set).isRequired,
  selectedViewOnlyAssetKeys: PropTypes.instanceOf(Set).isRequired,
  shareLinksGalleryView: PropTypes.bool,
  ugtLocale: PropTypes.string,
  userNotificationsPath: PropTypes.string,
  windowDimensions: PropTypes.shape({ innerHeight: PropTypes.number, innerWidth: PropTypes.number }),
};

BulkSelectBar.defaultProps = {
  activeLabelKey: null,
  assetsProcessing: 0,
  displayDownload: true,
  labels: {},
  libraryName: "Brandfolder",
  orgSearchPage: false,
  requestDownloadName: '',
  requestDownloadRequestee: '',
  shareLinksGalleryView: false,
  ugtLocale: '',
  userNotificationsPath: '',
  windowDimensions: {},
};

export default BulkSelectBar;
